import React from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import blogTemplateStyles from "./blogTemplate.module.scss"
import SEO from "../components/seo"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
} from "react-share"
import { Link } from 'gatsby';
import Grid from "@material-ui/core/Grid"

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      description
      publishedDate(formatString: "MMM Do, YYYY")
      body {
        json
      }
    }
  }
`

const Blog = props => {
  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img alt={alt} src={url} />
      },
    },
  }

  const findSeoImage = props.data.contentfulBlogPost.body.json.content.find(
    function(node) {
      return node.nodeType === "embedded-asset-block"
    }
  )
  const seoImage = findSeoImage.data.target.fields.file["en-US"].url

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <SEO
        title={props.data.contentfulBlogPost.title}
        url={`/blog/${props.data.contentfulBlogPost.slug}`}
        image={`https:${seoImage}`}
        description={props.data.contentfulBlogPost.description}
      />
      <div className={blogTemplateStyles.container}>
        <h1 className={blogTemplateStyles.title}>
          {props.data.contentfulBlogPost.title}
        </h1>
        <p className={blogTemplateStyles.date}>
          {props.data.contentfulBlogPost.publishedDate}
        </p>
        {documentToReactComponents(
          props.data.contentfulBlogPost.body.json,
          options
        )}
        <Grid container className={blogTemplateStyles.socialContainer}>
          <Grid
            item
            xs={12}
            sm={4}
            className={blogTemplateStyles.shareTextContainer}
          >
            <Link
              title="blog"
              className={blogTemplateStyles.svgLink}
              swipe
              direction="right"
              to="/blog"
            >
              <svg
                className={blogTemplateStyles.svg}
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  style={{ fill: "#141a3a" }}
                  d="M21.205,5.007c-0.429-0.444-1.143-0.444-1.587,0c-0.429,0.429-0.429,1.143,0,1.571l8.047,8.047H1.111
                                    C0.492,14.626,0,15.118,0,15.737c0,0.619,0.492,1.127,1.111,1.127h26.554l-8.047,8.032c-0.429,0.444-0.429,1.159,0,1.587
                                    c0.444,0.444,1.159,0.444,1.587,0l9.952-9.952c0.444-0.429,0.444-1.143,0-1.571L21.205,5.007z"
                />
              </svg>
            </Link>
            <h4 className={blogTemplateStyles.shareText}>Share On:</h4>
          </Grid>
          <Grid container xs={12} sm={8}>
            <Grid
              item
              xs={12}
              md={6}
              className={blogTemplateStyles.socialButtonGroup}
            >
              <WhatsappShareButton
                className={blogTemplateStyles.shareButton}
                url={props.location.href}
                children
              >
                <WhatsappIcon
                  size={60}
                  round
                  logoFillColor="#141a3a"
                  iconBgStyle={{ fill: "#fff" }}
                />
              </WhatsappShareButton>
              <FacebookShareButton
                className={blogTemplateStyles.shareButton}
                url={props.location.href}
                children
              >
                <FacebookIcon
                  size={60}
                  round
                  logoFillColor="#141a3a"
                  iconBgStyle={{ fill: "#fff" }}
                />
              </FacebookShareButton>
              <LinkedinShareButton
                className={blogTemplateStyles.shareButton}
                url={props.location.href}
                children
              >
                <LinkedinIcon
                  size={60}
                  round
                  logoFillColor="#141a3a"
                  iconBgStyle={{ fill: "#fff" }}
                />
              </LinkedinShareButton>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              className={blogTemplateStyles.socialButtonGroup}
            >
              <TwitterShareButton
                className={blogTemplateStyles.shareButton}
                url={props.location.href}
                children
              >
                <TwitterIcon
                  size={60}
                  round
                  logoFillColor="#141a3a"
                  iconBgStyle={{ fill: "#fff" }}
                />
              </TwitterShareButton>
              <TelegramShareButton
                className={blogTemplateStyles.shareButton}
                url={props.location.href}
                children
              >
                <TelegramIcon
                  size={60}
                  round
                  logoFillColor="#141a3a"
                  iconBgStyle={{ fill: "#fff" }}
                />
              </TelegramShareButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Blog
